// src/components/About.js
import React from 'react';
import './About.css'; // Import your About section CSS

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-content">
        <h2>About Me</h2>
        <p>
          Hello! I'm Shule Zhu from South Carolina. I am currently employed as a Software Engineer at Capgemini. 
          I focus on creating scalable, efficient, and innovative solutions that meet business needs.
          Throughout my career, I have earned several certifications and successfully delivered multiple applications from concept to deployment.
          My career goal is to continue growing in the software engineering field, with the aspiration of becoming a leader 
          who guides and mentors teams in building high-impact technology solutions.
        </p>
      </div>
    </section>
  );
};

export default About;
